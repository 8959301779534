<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-left-panel :span="12">
        <!-- <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button> -->
        <el-button type="primary" @click="syncGuest($event)">
          批量同步嘉宾信息
        </el-button>
        <el-button type="primary" @click="exportGuest($event)">
          导出嘉宾信息
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="嘉宾名称">
            <el-input v-model="queryForm.name" clearable placeholder="请输入嘉宾名称" />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="queryForm.status" placeholder="请选择状态">
              <el-option label="显示" :value="true" />
              <el-option label="隐藏" :value="false" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="头像" prop="avatar">
        <template #default="{ row }">
          <el-image fit="cover" :src="row.avatar ? row.avatar :  'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/images/meet-avatar-default.png'" style="width: 100px; height: 100px" />
        </template>
      </el-table-column>
      <el-table-column label="嘉宾名称" prop="name" show-overflow-tooltip />
      <el-table-column label="机构名称" prop="company"  />
      <el-table-column label="职位" prop="position"  />
      <el-table-column label="嘉宾介绍" prop="introduction" show-overflow-tooltip/>
      <el-table-column label="参与论坛" prop="meetingNames"  />
      <el-table-column label="创建时间" prop="createTime"  />
      <el-table-column label="状态" prop="signNum" width='200'>
        <template #default="{ row }">
          <el-switch v-model="row.status" class="ml-2" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ccc" active-text="显示" inactive-text="隐藏" @change="changeStatus($event, row)"/>
        </template>
      </el-table-column>
      <!-- <el-table-column label="渠道唯一标识" prop="channelNo" show-overflow-tooltip />
      <el-table-column label="渠道二维码" prop="channelQrcodeUrl" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image fit="cover" :src="row.channelQrcodeUrl" style="width: 100px; height: 100px" />
        </template>
      </el-table-column>

      <el-table-column label="渠道链接" prop="channelUrl" show-overflow-tooltip /> -->
      <el-table-column label="操作" show-overflow-tooltip width="420">
        <template #default="{ row }">
          <el-row>
            <el-button type="primary" @click="handleTranslate(row)">
              重新翻译
            </el-button>
            <el-button type="primary" @click="handleEdit(row)">
              编辑
            </el-button>
          </el-row>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getChannelList, _exportUserInfo } from '@/api/channel'

import {
  syncGuests,
  queryGuestList,
  translationGuest,
  updateGuestStatus,
  exportGuestInfo
} from '@/api/exhibitionManage'
import handleClipboard from '@/utils/clipboard'
export default defineComponent({
  name: 'ChannelManagar',
  components: {
    Edit: defineAsyncComponent(() => import('./components/guestEdit.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        name: '',
        pageNum: 1,
        pageSize: 10,
        language: 'CN',
        status: "",
      },
      type: '',
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'total, sizes, prev, pager, next, jumper',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      channelDetailRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
      hostFlag: 'HOST',
    })

    const handleEdit = (row) => {
      console.log(row)
      if (row.code) {
        state.editRef.showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delCoupon(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await queryGuestList(state.queryForm)
      state.list = (data && data.list) || []
      state.total = (data && data.total) || 0
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }

    onMounted(() => {
      queryData()
    })

    const syncGuest = async () => {
      const { data } = await syncGuests()
      queryData()
    }

    const handleTranslate = async (row) => {
      const { data } = await translationGuest(row.code)
      queryData()
    }

    const changeStatus = async (status,row) => {
      console.log(status, row)
      await updateGuestStatus(row.code, status)
      queryData()
    }

    const exportGuest = async () => {
      const { data } = await exportGuestInfo()
      window.open(data.url)
    }

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
      syncGuest,
      handleTranslate,
      changeStatus,
      exportGuest
    }
  },
})
</script>
<style lang="scss" scoped></style>
